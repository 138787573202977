.testimonial-slider {
  .swiper-wrapper {
    padding: 40px 0;
  }
  .testimonial-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 1.5rem;
    background-color: white;
    border-radius: 0.75rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    min-height: 380px;
  }

  .swiper-slide {
    display: flex;
    align-items: stretch; /* Ensures all slides have equal height */
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    color: #666;
    flex-grow: 1; /* Makes the paragraph fill up the remaining space */
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
