@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Bold.eot');
    src: url('Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Bold.woff2') format('woff2'),
        url('Poppins-Bold.woff') format('woff'),
        url('Poppins-Bold.ttf') format('truetype'),
        url('Poppins-Bold.svg#Poppins-Bold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Black.eot');
    src: url('Poppins-Black.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Black.woff2') format('woff2'),
        url('Poppins-Black.woff') format('woff'),
        url('Poppins-Black.ttf') format('truetype'),
        url('Poppins-Black.svg#Poppins-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-ExtraBold.eot');
    src: url('Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Poppins-ExtraBold.woff2') format('woff2'),
        url('Poppins-ExtraBold.woff') format('woff'),
        url('Poppins-ExtraBold.ttf') format('truetype'),
        url('Poppins-ExtraBold.svg#Poppins-ExtraBold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-SemiBold.eot');
    src: url('Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Poppins-SemiBold.woff2') format('woff2'),
        url('Poppins-SemiBold.woff') format('woff'),
        url('Poppins-SemiBold.ttf') format('truetype'),
        url('Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Medium.eot');
    src: url('Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Medium.woff2') format('woff2'),
        url('Poppins-Medium.woff') format('woff'),
        url('Poppins-Medium.ttf') format('truetype'),
        url('Poppins-Medium.svg#Poppins-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Regular.eot');
    src: url('Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Regular.woff2') format('woff2'),
        url('Poppins-Regular.woff') format('woff'),
        url('Poppins-Regular.ttf') format('truetype'),
        url('Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Light.eot');
    src: url('Poppins-Light.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Light.woff2') format('woff2'),
        url('Poppins-Light.woff') format('woff'),
        url('Poppins-Light.ttf') format('truetype'),
        url('Poppins-Light.svg#Poppins-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}